import { ApiService } from 'src/app/services/api.service'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {
    code: any
    codeStatus = 'inProgress'
    constructor(
        public api: ApiService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.code = this.route.snapshot.paramMap.get('code')
        // console.log(this.code)
    }

    ngOnInit() {
        this.api.checkVerificationCode({code :this.code}).subscribe((resp: any) => {
            console.log(resp)
            if (resp.success === false) {
                this.codeStatus = 'expire'
                return
            }
            this.codeStatus = 'valid'
            localStorage.setItem('token', resp.data.token)
            localStorage.setItem('user', JSON.stringify(resp.data))
            this.api.user = resp.data
            this.api.userLoggedInSource.next(true)
            this.api.doUserRedirects(resp, this.router)
        })
    }
    
    resendCode() {
        this.api.resendVerificationCode({ code: this.code }).subscribe((resp: any) => {
            if (resp.success === true) {
                this.codeStatus = 'resent'
                return
            }
            this.codeStatus = 'invalidCode'
        })
    }

}
